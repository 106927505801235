// @font-face {
//   font-family: "Metropolis";
//   src: url("../assets/fonts/Metropolis-Regular.otf") format("opentype");
// }

// Here you can add other styles
.input-group-icon {
  position: relative;
  > .icon {
    position: absolute;
    z-index: 9;
    top: 50%;
    transform: translate(0, -50%);
    font-size: $input-btn-font-size;
  }
  > .icon:not(:first-child) {
    right: $input-padding-x;
  }
  > .icon:not(:last-child) {
    left: $input-padding-x;
  }
  .form-control:not(:first-child) {
    padding-left: calc(calc($input-padding-x * 2) + $input-btn-font-size);
  }
  .form-control:not(:last-child) {
    padding-right: calc(calc($input-padding-x * 2) + $input-btn-font-size);
  }
}

.table-general {
  thead {
    tr {
      th {
        background-color: transparent !important;
        color: $primary !important;
      }
    }
  }
  tbody {
    tr {
      td {
        background-color: #ffffff !important;
        color: $primary !important;
      }
    }
  }
}

.pagination-general {
  > button {
    color: $primary;
    &.active {
      color: #fff;
      background-color: $primary;
      border-radius: 32px;
    }
    &:disabled {
      border: none;
    }
  }
}

// Remove text decoration on breadcrumb link
.breadcrumb {
  .breadcrumb-item {
    a {
      text-decoration: none;
    }
  }
}

.text-title {
  font-size: $font-size-lg !important;
  line-height: $line-height-lg !important;
}

.text-body {
  font-size: $font-size-base !important;
  line-height: $line-height-base !important;
}

.text-small {
  font-size: $font-size-sm !important;
  line-height: $line-height-sm !important;
}

// .text-medium {
//   font-size: $font-size-md !important;
//   line-height: $line-height-md !important;
// }

.cursor-pointer {
  cursor: pointer;
}

// Dashboard
.dashboard {
  .leads-assigned-status,
  .leads-time-average {
    .name {
      font-size: 10px;
      line-height: 14px;
      color: #979797;
    }

    .percentage {
      font-size: 14px;
      line-height: 19px;
    }

    .total {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
    }
  }
  .overview-deal {
    .border-bottom-absolute {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 2px;
        bottom: 0;
        height: 2px;
        width: calc(100% - 4px); /* or 100px */
        border-bottom: 2px solid #ffffff;
      }
    }
  }
}

// Custom modal style
.modal {
  .modal-content {
    // max-height: calc(100vh - ($modal-dialog-margin-y-sm-up * 2)) !important;
    // .modal-body {
    //   overflow-y: auto;
    // }
    .modal-footer {
      .action-btn-group {
        width: 100% !important;
        max-width: 466px;
        .btn {
          width: 100% !important;
          font-weight: bold;
        }
      }
    }
  }
}

// Custom form classes
.form-control.disabled {
  background-color: #f2f2f2;
  border-color: #b1b7c1;
  opacity: 1;
}
