.rbt {
  &.is-invalid {
    .form-control {
      border-color: $danger;
      &:focus {
        box-shadow: 0 0 0 0.25rem rgb(208 36 42 / 25%);
      }
    }
  }
}
