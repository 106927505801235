.input-group-icon {
  .react-datepicker-wrapper {
    width: 100%;
  }
}

.react-datepicker-wrapper {
  .react-datepicker__input-container {
    .form-control {
      &.is-invalid {
        background-image: none !important;
      }
    }
  }
}

.react-datepicker-popper {
  .react-datepicker {
    .react-datepicker__year-dropdown-container {
      .react-datepicker__year-dropdown {
        .react-datepicker__year-option {
          .react-datepicker__navigation {
            &.react-datepicker__navigation--years {
              &::before {
                border-color: #ccc;
                border-style: solid;
                border-width: 3px 3px 0 0;
                content: "";
                display: block;
                height: 9px;
                position: absolute;
                top: 6px;
                width: 9px;
              }
              &.react-datepicker__navigation--years-upcoming {
                &::before {
                  transform: rotate(-45deg);
                  left: 11px;
                  top: 16px;
                }
              }
              &.react-datepicker__navigation--years-previous {
                &::before {
                  transform: rotate(135deg);
                  left: 11px;
                  top: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
