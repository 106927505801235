.react-select {
  .react-select__control {
    min-height: 34px;
    overflow-y: auto;
    border-radius: $input-border-radius;
    border-color: $input-border-color;
    &--is-focused {
      border-color: $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
    }
    &:hover {
      border-color: $input-focus-border-color;
    }
    .react-select__value-container {
      padding: 0 $input-btn-padding-x;
      max-height: calc(34px * 3 + 10px);
      overflow-y: auto;
      .react-select__placeholder {
        margin-left: 0px;
        color: $input-placeholder-color;
      }
      .react-select__single-value {
        margin-left: 0px;
        color: $input-color;
        font-size: $input-btn-font-size;
      }
      .react-select__input-container {
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
        font-size: $input-btn-font-size;
      }
    }

    .react-select__indicators {
      .react-select__indicator-separator {
        margin-top: $input-btn-padding-y;
        margin-bottom: $input-btn-padding-y;
      }
      .react-select__indicator {
        padding: 0 calc($form-select-padding-x + 3px);
        color: $form-select-indicator-color;
      }
    }
  }

  .react-select__menu {
    z-index: 100;
    .react-select__menu-list {
      .react-select__option {
        padding: $input-btn-padding-y $input-btn-padding-x;
        &--is-focused {
          background-color: rgba($primary, 0.3);
        }
        &--is-selected {
          background-color: $primary;
        }
      }
    }
  }

  &.react-select-lg {
    .react-select__control {
      min-height: 44px;
      .react-select__value-container {
        padding: 2px 16px;
      }
    }

    .react-select__menu {
      .react-select__menu-list {
        .react-select__option {
          padding: 8px 16px;
        }
      }
    }
  }

  &.is-invalid {
    .react-select__control {
      border-color: $danger;
      &--is-focused {
        box-shadow: 0 0 0 0.25rem rgb(208 36 42 / 25%);
      }
    }
  }
}
